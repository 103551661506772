export const sharedObjects = {
  // List of other ingredients that can be added to a recipe via dropdown selection
  otherIngredients: [
    {
      value: "bakers_non_fat_dry_milk",
      description: "Baker's Non-Fat Dry Milk",
      ratio: 0.1375661,
      key: "o1",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "baking_powder",
      description: "Baking Powder",
      ratio: 0.1410934,
      key: "o2",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "baking_soda",
      description: "Baking Soda",
      ratio: 0.1410934,
      key: "o3",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "butter_margarine",
      description: "Butter/Margarine",
      ratio: 0.1668136,
      key: "o4",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "buttermilk,_dry",
      description: "Buttermilk, dry",
      ratio: 0.0705467,
      key: "o5",
      note: "Recommended 4.0 - 6.0%",
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "buttermilk,_fresh_lowfat",
      description: "Buttermilk, fresh (lowfat)",
      ratio: 0.1800411,
      key: "o6",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "canola_oil",
      description: "Canola Oil",
      ratio: 0.1601998,
      key: "o7",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "citric_acid",
      description: "Citric Acid",
      ratio: 0.17636995,
      key: "o39",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "clarified_butter_ghee",
      description: "Clarified Butter (Ghee)",
      ratio: 0.1506466,
      key: "o8",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "corn_flour",
      description: "Corn Flour",
      ratio: 0.0852439,
      key: "o9",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "corn_oil",
      description: "Corn Oil",
      ratio: 0.1587301,
      key: "o10",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "corn_syrup",
      description: "Corn Syrup",
      ratio: 0.2398098,
      key: "o11",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "cornmeal",
      description: "Cornmeal",
      ratio: 0.116843,
      key: "o12",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "cream_of_tartar",
      description: "Cream of Tartar",
      ratio: 0.1058201,
      key: "o13",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "diastatic_milk_powder",
      description: "Diastatic Malt Powder",
      ratio: 0.0881834,
      key: "o14",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "dry_non_fat_milk_carnations",
      description: "Dry Non-Fat Milk (Carnation's)",
      ratio: 0.0507054,
      key: "o15",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "egg_whites_fresh,_raw",
      description: "Egg Whites (fresh, raw)",
      ratio: 0.1785714,
      key: "o16",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "eggs_large,_fresh,_raw",
      description: "Eggs (large, fresh, raw)",
      ratio: 0.1785714,
      key: "o17",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "honey",
      description: "Honey",
      ratio: 0.2466666,
      key: "o18",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "lard",
      description: "Lard",
      ratio: 0.1528512,
      key: "o19",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "malted_milk_powder_carnations",
      description: "Malted Milk Powder (Carnation's)",
      ratio: 0.0823045,
      key: "o20",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "maple_syrup_pure",
      description: "Maple Syrup (pure)",
      ratio: 0.2366255,
      key: "o21",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "milk_fresh",
      description: "Milk (fresh)",
      ratio: 0.1763668,
      key: "o22",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "molasses",
      description: "Molasses",
      ratio: 0.2443415,
      key: "o23",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "non_diastatic_barley_malt_syrup",
      description: "Non-Diastatic Barley Malt Syrup",
      ratio: 0.2469135,
      key: "o24",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "olive_oil",
      description: "Olive Oil",
      ratio: 0.1587301,
      key: "o25",
      note: "Typical: 1.5",
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "potatoflour",
      description: "Potato Flour",
      ratio: 0.134039,
      key: "o26",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "riceflour",
      description: "Rice Flour",
      ratio: 0.0881849,
      key: "o39",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "wholewheat",
      description: "White Whole Wheat",
      ratio: 0.0881849,
      key: "o40",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "whitewholewheat",
      description: "Whole Wheat",
      ratio: 0.0881849,
      key: "o41",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "pz_44",
      description: "PZ-44",
      ratio: 0.083333,
      key: "o27",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "rye_flour",
      description: "Rye Flour",
      ratio: 0.0813247,
      key: "o28",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "semolina",
      description: "Semolina",
      ratio: 0.1227219,
      key: "o29",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "shortening",
      description: "Shortening",
      ratio: 0.140934,
      key: "o30",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "soy_flour",
      description: "Soy Flour",
      ratio: 0.0705467,
      key: "o31",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "sugar",
      description: "Sugar",
      ratio: 0.140625,
      key: "o32",
      note: "Typical: 1",
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "sweet_dried_dairy_whey",
      description: "Sweet Dried Dairy Whey",
      ratio: 0.1065549,
      key: "o33",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "vegetable_soybean_oil",
      description: "Vegetable (Soybean) Oil",
      ratio: 0.1601998,
      key: "o34",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "vinegar",
      description: "Vinegar",
      ratio: 0.1763668,
      key: "o35",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "vital_wheat_gluten",
      description: "Vital Wheat Gluten",
      ratio: 0.0975896,
      key: "o36",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "wrise",
      description: "WRISE",
      ratio: 0.1834215,
      key: "o37",
      note: null,
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "yellow_food_coloring",
      description: "Yellow Food Coloring",
      ratio: 0.1975308,
      key: "o38",

      note: null,
      dropsInTsp: 90,
      amount: null,
    },
  ],

  // Other ingredients that are applied to a recipe by default
  defaultAddedIngredients: [
    {
      value: "olive_oil",
      description: "Olive Oil",
      ratio: 0.1587301,
      key: "o25",
      note: "Typical: 1.5",
      dropsInTsp: null,
      amount: 1.5,
    },
    {
      value: "sugar",
      description: "Sugar",
      ratio: 0.140625,
      key: "o32",
      note: "Typical: 1",
      dropsInTsp: null,
      amount: 1.0,
    },
  ],

  // Same as above but with 0 amounts
  resetAddedIngredients: [
    {
      value: "olive_oil",
      description: "Olive Oil",
      ratio: 0.1587301,
      key: "o25",
      note: "Typical: 1.5",
      dropsInTsp: null,
      amount: null,
    },
    {
      value: "sugar",
      description: "Sugar",
      ratio: 0.140625,
      key: "o32",
      note: "Typical: 1",
      dropsInTsp: null,
      amount: null,
    },
  ],

  defaultTableConfig: {
    show: {
      grams: true,
      kilograms: false,
      milliliters: false,
      ounces: false,
      pounds: false,
      teaspoons: false,
      tablespoons: false,
      cups: false,
      drops: false,
      title: true,
      notes: true,
    },
    precision: 1,
    precisionString: "1.0-3",
  },

  // In the tips tab
  tips: [
    {
      key: "simple_mode",
      text: "Simple Mode does the heavy lifting. All you need to do is input the desired size and how many pizzas you want.",
    },
    {
      key: "approach",
      text: "You can determine your dough amount by thickness or weight.<br><br>Thickness Factor measures the dough by its height, from bottom to top. It serves as a constant to keep dough thickness consistent across all diameter sizes.<br><br>Dough Weight is useful if you know the exact amount of dough you need for each pizza.",
    },
    {
      key: "dough_amount",
      text: "Thickness Factor amount is a ratio of pan size to dough thickness. It allows you to change the pan size without changing the thickness of the dough. If you want your dough to be thicker, you would raise the thickness factor. By default, use 0.08 (New York style thickness) and tweak if needed. If you're interested, Thickness Factor or Dough Loading Factor is an interesting rabbit hole. For now though, set it to the default and work from there. ",
    },
    {
      key: "pizza_shape",
      text: "Round: measured by diameter.<br><br>Rectangular: measured by width and length.<br><br>Deep Dish: similar to rectangular, but requires more measurements for accuracy.<br><br>Stuffed with Outer Skin percentage determines how much of your dough will be layered on top.<br><br>14 inches (35.56 cm) is pretty standard for a regular round pizza.",
    },
    {
      key: "hydration",
      text: "Percentage of water compared to flour. For example, if you have 100g of flour, and have hydration set to 62%, you'll use 62g of water.<br><br>Use 62% as the default value to work from.",
    },
    {
      key: "yeast_amount",
      text: `Yeast is important for both flavor and rising the dough.<br><br>IDY: Instant Dry Yeast.<br><br>ADY: Active Dry Yeast.<br><br>Cake Yeast: live yeast, equivalent to Fresh Yeast or Compressed Yeast<br><br>Make sure your yeast is still viable before use. One way to do this is while you are making your dough, mix the water (warm), yeast, and sugar first. Within a few minutes you should see some movement as the reaction occurs ("blooming").<br><br>This value is computed as a percentage against the flour.<br><br>You can use 0.4 as a starting point.`,
    },
    {
      key: "preferment",
      text: `"Preferment" is a fermentation starter used in making dough. Variations include Biga, Poolish, Sponge, Old Dough, Pre-dough, Mother Dough, Sourdough Starter, and any others used to make sourdough. If you're unfamiliar with it, that's okay. You may never use it but it's in here for those that might.<br><br>This will create a secondary batch (cut from the whole) of dough in the Recipe tab that you will mix separately per the preferment process. If you receive any negative numbers in your Recipe table, you may have attempted to use more yeast, flour, or water than was available from the total.<br><br>Use "None" as default.`,
    },
    {
      key: "salt",
      text: "Different kinds of salt can produce different flavor.<br><br>Regular is the default. <br><br>This value is computed as a percentage against the flour.<br><br>1.0 is a starting point. There's room to experiment here.",
    },
    {
      key: "additional_ingredients",
      text: "It's pretty standard to use olive oil (1.5 default) and sugar (1.0 default), but many more options are added for your convenience. <br><br>These values are computed as a percentage against the flour, just like the water is.",
    },
    {
      key: "bowl_residue_compensation",
      text: "Dough is sticky.<br><br>After making dough, residue may be stuck inside your bowl, and you'll lose some from your weight total. If you're hoping to be extra precise with your weight values, this will add a little extra to the total to account for dough left behind. Entering a value here is completely optional.<br><br>This value is computed as a percentage against the flour.<br><br> Start small, under 1% and then check the Recipe tab to see how much dough you'd lose.",
    },
    {
      key: "split_water",
      text: "If you'd like to withhold water until later in the batch, you can set this up here. Percentages are measured against the total water amount.<br><br>All values must amount to 100. If you aren't using preferment, then those split water options will be disabled.",
    },
    {
      key: "split_flour",
      text: "If you'd like to withhold flour until later in the batch (or make a distinction between different types of flour), you can set this up here.<br><br>Percentages are measured against the total flour amount. Any flour added from Additional Ingredients isn't included in this calculation.<br><br>All values must amount to 100. If you aren't using preferment, then those split flour options will be disabled.",
    },
    {
      key: "prefermenttf",
      text: `<strong class="tooltip-inner-title">% of Total Flour</strong><br>(most popular and widely used)<br><br>
      <ul class="tooltip-list">
      <li><strong>Preferment Amount %</strong><br>Weight of preferment as percentage of total flour (including the preferment portion)</li>
      <li><strong>Preferment Yeast Amount %</strong><br>Preferment yeast as percentage of the total preferment</li>
      <li><strong>Preferment Water Amount %</strong><br>Preferment water as a percentage of the total preferment</li>
      </ul>`,
    },
    {
      key: "prefermenttw",
      text: `<strong class="tooltip-inner-title">% of Total Water</strong><br><br>
      <ul class="tooltip-list">
      <li><strong>Preferment Amount %</strong><br>Weight of preferment as percentage of total dough weight (including the preferment portion)</li>
      <li><strong>Preferment Yeast Amount %</strong><br>Preferment yeast as percentage of the total preferment</li>
      <li><strong>Preferment Water Amount %</strong><br>Preferment water as a percentage of the total preferment</li>
      </ul>`,
    },
    {
      key: "prefermenttdw",
      text: `<strong class="tooltip-inner-title">% of Total Dough Weight</strong><br><br>
      <ul class="tooltip-list">
      <li><strong>Preferment Amount %</strong><br>Weight of preferment as percentage of total flour (including the preferment portion)</li>
      <li><strong>Preferment Yeast Amount %</strong><br>Preferment yeast as percentage of the total preferment</li>
      <li><strong>Preferment Water Amount %</strong><br>Preferment water as a percentage of the total preferment</li>
      </ul>`,
    },
    {
      key: "prefermente",
      text: `<strong class="tooltip-inner-title">% of Each</strong><br><br>
      <ul class="tooltip-list">
      <li><strong>Preferment Flour Amount %</strong><br>Weight of preferment flour as percentage of total flour (including the preferment portion)</li>
      <li><strong>Preferment Yeast Amount %</strong><br>Preferment yeast as percentage of the total yeast  (including the preferment portion) </li>
      <li><strong>Preferment Water Amount %</strong><br>Preferment water as a percentage of the total water  (including the preferment portion) </li>
      </ul>`,
    },
    {
      key: "notes",
      text: `Be kind to yourself, leave yourself some notes for later while it's on your mind.<br><br>Markdown (and HTML) is supported`,
    },
  ],

  miscInfo: [
    {
      id: 3,
      title: "Preferment",
      content: `Variations are also called Biga, Poolish, Sponge, Old Dough, Pre-dough, Mother Dough, Sourdough Starter, and likely others. 
      Prefermentation is a small batch of dough made and fermented ahead of time, then mixed in with the final batch. 
      This increases the complexities of flavor, and improves texture. If you'd like to have a
      portion of your recipe be used for prefermentation, you can enable that
      in the 'preferment' section and enter how much you want to use as part of
      your starter. A similar effect can be achieved by instead creating a normal batch of pizza dough and putting it in the fridge for 2-5 days, cold fermenting it while it rises. Much of the
      flavor is developed during this time. The end result won't be identical to using a separate fermentation batch, as a cold ferment slows down the yeast growth and develops a different kind of flavor. I would never make pizza dough without doing one or the other. 
      It's recommended, and delicious. 
      <br><br>
      If this is your first time, start with a cold ferment and try prefermentation later on when you're comfortable with making dough. 
      <br><br>
      If you're looking to input an old Poolish/Biga/other into the app, here's something of note:<br>
      Most of these older techniques or recipes are for commercial yeast. 
      In a home kitchen where you'd use wild or natural yeast, these methods won't work. 
      There are a bastardization of commercially leavened preferments out there that are supposed to be adapted for a home kitchen, but are inconsistent or generate sporadic results. 
      The preferment formulas used in this app are consistent, and are tried and true, so I highly recommend learning them. 
      If you have an old recipe, or just want to learn, you may find some useful information 
      <a href="https://www.pizzamaking.com/forum/index.php?topic=59104.msg592940#msg592940" target="_blank" rel="noopener noreferrer">here</a>.`,
    },
    {
      id: 4,
      title: "Storing dough",
      content: `Storing your dough in glass containers is better overall (and safer) than
      plastic in terms of fermentation and flavor. This isn't your highest investment priority, but something to consider down the line.<br><br>
      <strong>Fridge</strong><br><br>Depending on elevation and temperature, dough can be stored in a fridge for up to a week. The sweet spot for me is about 3-5 days, I've been fine going as many as 7. 
      If you go up to 10, it becomes a little too fermented for pizza, but you can roast it
      on a stone with some olive oil, cracked pepper, and sea salt, and create
      bread sticks that taste excellent in olive oil and balsamic vinegar.
      Experiment a little, see what you like.<br><br><strong>Freezer</strong><br><br>If you'd like to store it in the freezer, your mileage may vary. As long as you have already divided the
      dough, and it's already risen, then you can store it in the fridge for
      two or three months. Let thaw in the fridge over night before using.`,
    },
    {
      id: 5,
      title: "Markdown support",
      content: `Markdown is supported in Notes! Markdown is a text-to-HTML converter. It makes it easy to add lists, links, text formatting, and more. <a href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet">Here's a cheat sheet for a quick introduction.</a>`,
    },
    {
      id: 6,
      title: "Move recipes across platforms",
      content: `This is a problem I'm trying to solve without introducing a server. Here are your current options:<br><br>To go from app to web, go to the Calculator tab and tap the Share button, you have the option of creating a URL which you can save to your clipboard. This link can be shared or sent elsewhere, and will open your current recipe in the web version.<br><br>To go from web to the app, you can copy the web generated link and import it (through the Share button). You can also use your phone to scan a QR code generated from the webpage. From there you can save it as a new recipe, or overwrite an existing one. Deep linking has been implemented, which means by opening a web URL you have the option of opening it in the app instead.<br><br>Keep in mind URLs have a character limit, so be mindful of how long your notes are before trying any of these.`,
    },
    {
      id: 7,
      title: "Additional ingredients list",
      content: `There's a variety of additional ingredients to choose from. If there's an ingredient missing and you feel it would be beneficial to have it in the app, please reach out to me and request it. Here are some common ones you'll find:<br><br>
      <strong>Olive Oil</strong><br><br>Commonly used in pizza dough, good for flavor. Downside is making the dough tear more easily.<br><br>
      <strong>Sugar</strong><br><br>Sweetens the dough, as expected. But also serves to feed the yeast, aiding in the reaction. Although technically the yeast can function without added sugar (feeding off the starch in the flour), it can certainly speed things up.<br><br>
      <strong>Diastatic Malt Powder</strong><br><br>Helps with browning.<br><br>
      <strong>Citric Acid</strong><br><br>Increases dough strength and elasticity.<br><br>`,
    },
    {
      id: 8,
      title: "Using quality ingredients",
      content: `<strong>Dough flour</strong><br><br>If you're looking into what kind of flour to use, all-purpose flour is
      just fine. If you want to take it up a notch, look for high protein bread
      flour or neapolitan flour. Neapolitan can cook at much higher temperatures. 
      While typically my bread flour dough will burn at above 750°F, Neapolitan flour dough can be cooked in the 900°F range and can develop a more distinct puffy cracker crust. 
      <br><br><strong>Cheese</strong><br><br>If you're cooking in an oven, the best melting cheese on pizza is a full fat (whole milk) low moisture
      cheese. If you're using a pizza oven with temperatures up to 750°F, half low moisture half regular moisture works well. If you're cooking at a higher temperature, you can get away with wetter cheese. 
      The rule of thumb is this: the longer the cheese is in the oven, the more moisture (oil and water) will be flushed out, resulting in a greasy pizza. 
      When you cook at a higher temperature, the cheese will be under the heat for less time.
      The goal is to aim for great flavor, no puddles. If your pizzas are greasy in spite of what you do, use less cheese. You don't need as much as you think, be conservative!
      <br /><br /><strong>Flour (for non-stick surface)</strong><br><br>A factor in crust flavor is how you dust the surface on which you prepare your pizza. Your options, ranked from most tasty to least (in my opinion) are as follows:
      '00' flour (neapolitan), semolina, roasted flour, then cornmeal. None are bad, but the first two blend naturally well, with roasted flour having a distinct (but tasty) flavor. 
      Cornmeal is very common, effective, and inexpensive, but the gritty flavor isn't always preferred. If you have flour on hand and want to make roasted flour, put a cup of flour in a pan at medium-to-high heat,
      and watch it carefully (it burns fast!). Stir occasionally, especially
      once it gets hot enough. When golden brown, remove from heat. You can store it in a container for future use.`,
    },
    {
      id: 9,
      title: "Stretching Dough",
      content: `Dough tearing is common. One reason we have this calculator is to
      gradually work out the kinks in our ratios. 
      If this is your first time, and you're doing a New York style dough, the dough consistency
      shouldn't be sticky or gooey, but it shouldn't be dry and bready either.
      This comes down do your flour/water ratio (hydration percentage). 
      Gluten formation is also critical, as that's what creates the stretchy strands that make the dough elastic. 
      Mixing the dough for enough time can make all the difference. Also,
      adding a tiny amount (value: 0.13 from Additional Ingredients) of citric
      acid can help.<br /><br />
      
      Here are some common ways to stretch dough before topping: <br />
      <i>Note: firstly, make the dough into a puck shape. Put it on a flour dusted surface and work the dough with your hands in a circular motion until it resembles a undersize pizza or a flat symmetrical puck.</i><br />
      1. Gravity method. After pushing your dough into a puck shape on your
      surface, grasp one side using both hands. Make sure all non-thumb fingers
      are grasping the dough like a clamp. Hold dough above your surface and let
      gravity stretch it out. Rotate the dough through your hands as to ensure
      all sides can stretch out, and that no one side stretches enough to tear
      the dough.<br />
      2. Knuckles method. This usually works best once the dough has been
      stretched out just a little, possibly by the gravity method. Place the
      dough on top of the back of your knuckles, fingers bent toward your palm.
      Ensure your knuckles are holding up the dough on one side. Now slowly and
      gently pull your knuckles apart, stretching that edge of the dough. Scoot
      the dough in a small rotation, repeating the stretching. Essentially
      you're using your knuckles to stretch out the edges of the dough in a
      rotational fashion, which in turn will expand the dough. Consider using
      floured hands to prevent sticking.<br><br>
      Practice with these enough, and work out the kinks in your dough recipe, and you'll be well on your way to throwing pizza dough. 
      I am not qualified to leave instructions on how to do that, but the knuckles method is a good precursor. Practice that one well before learning to throw.`,
    },
    {
      id: 10,
      title: "Cooking",
      content: `Pizza stones (or pizza steels) are excellent at building heat and
        cooking your crust. Place in oven at mid-to-top rack, and heat it as hot
        as your oven will go (typically 550F or 260C) an hour ahead of time. It's
        important to heat it up an hour ahead of time or the stone/steel won't get hot
        enough. Cook pizza directly on stone or steel (not a pan) for roughly 5-7 minutes. The
        stone will help get you a leopard spotting on bottom. The mid to top rack
        will help golden/slightly char the top. Absolutely delicious. 
        Please note that pizza steels cook a lot hotter, and in some cases may burn the bottom of your pizza before the top is ready. 
        It depends on the oven, of course, but I've had better luck with a stone.<br /><br />
        If you want to cook your pizza in a pan, and don't have a stone, the steps are mostly the same. Stretch your dough into your pan, top it, and cook it. 
        It'll take longer without a stone, 8-15 minutes.
        <br /><br />
        If you're cooking in an official pizza oven, there are more variables at play. The moisture of the dough and cheese, gas vs wood, neapolitan vs other flours. 
        Check the section on "Using quality ingredients" for some insight.
        <br /><br />
        If cooking on a pizza stone or steel, consider also investing in a large
        wooden pizza peel (or a non-stick metal one) and a thin metal pizza peel. The wooden one, with a thin
        layer of '00' flour (neapolitan), semolina, roasted flour, or cornmeal is an excellent surface to build your
        pizza on top of without sticking, which then can slide it right off into
        the oven when ready. A metal peel can slide underneath the now cooked
        pizza to pull it out safely.`,
    },
    {
      id: 11,
      title: "Pizza sizes",
      content: `Your most popular American pizza franchises say a large pizza is 14" (35.6 cm). Others, and a handful of online websites dedicated to this topic argue that a large is 16". I don't know who is correct. "Personal" size is also up for debate, anywhere from 6-10 inches. For the purposes of this app, the set sizes for Simple Mode are as follows:<br><br>- Personal - 7" / 17.78 cm<br>- Small - 10" / 25.4 cm<br>- Medium - 12" / 30.5 cm<br>- Large - 14" / 35.6 cm<br>- XL - 16" / 40.6 cm<br>- XXL - 18" / 45.72 cm`,
    },
    {
      id: 12,
      title: "Premade recipes",
      content: `Premade recipes are provided by the community. If you have a recipe you'd like to contribute, please consider sharing! I can be reached by email at <a href="">pizza@porterlyman.com</a>.`,
    },
    {
      id: 13,
      title: "Non-pizza recipes",
      content:
        "Can you use this app for non-pizza related doughs? I don't see why not. It's a given that this app's main focus is on pizza. Notwithstanding there may be a few missing features that bakers may need, I think this app has good potential to become a tool used by all dough enthusiasts. I've had good success using it with bread recipes. <a href='https://www.products.porterlyman.com/miscellaneous-dough-recipes'>Here are recipes I use that you can check out</a>.",
    },
  ],

  // Tooltips in the guided recipe
  guidedRecipeTips: [
    {
      key: "instruction_disclaimer",
      text: "If you're looking to cook pizza on a pan, the instructions are very similar. Instead of a stone and paddles, you'll just stretch your dough onto your pan, top it, then slide it into the oven (same temperature), and cook for anywhere from 6 - 16 minutes. Pull out when golden brown on top. The difficulty (and main difference) in doing it this way is it's more difficult to brown the bottom of the crust, and the crust will be a little more bready. Experimentation is key; you can still cook a delicious pizza this way.",
    },
    {
      key: "pizza_stone",
      text: "Two options here: pizza stone or pizza steel. <br><br>A pizza stone seems to be more common, but a pizza steel (basically just a large rectangular metal plate) does a much better job at retaining heat.<br><br> The reason you'll want one is the difference between baking bread and cooking a crust. New York style (and similar style) pizzas require a higher heat than a traditional home oven can provide. A pizza stone/steel absorbs heat and gets hotter than the internal temperature of your oven, cooking the pizza much faster, which will improve your crust. <br><br>When buying a pizza stone, it's safest to make sure it's at least large enough for the size of your pizza, but make sure it's not larger than your oven can hold.",
    },
    {
      key: "wooden_peel",
      text: "The idea behind the wooden pizza peel (or paddle) is that it's large enough to prepare a pizza on, and mobile enough to move your pizza to your oven. It's best to have at least one peel for that purpose, if you end up not getting both kinds, and it's best to get one that's larger than you're anticipated pizza size. For example, if you plan on making a 14 inch pizza, a 18 to 20 inch peel is perfect.",
    },
    {
      key: "metal_peel",
      text: "A metal peel is really good at one thing: removing the pizza from the oven. It's thin and heat resistant, and doesn't need to be quite as big. Alternatively you could use metal tongs to pull your pizza out onto a pan or cooling rack. For a metal peel, you only need on that is at least the size of your pizza. For example, if you're making a 14 inch pizza, you'll need a metal peel that's at least 14 inches in diameter. ",
    },
    {
      key: "kitchen_scale",
      text: "Having a kitchen scale is highly recommended. Weight measurements are superior to volumetric measurements. Although this app will give you some volumetric measurements, it's always best to measure by weight. See the tooltip by MeasurementType for more info.",
    },
    {
      key: "why-metric-weight",
      text: "<strong>You should always measure by weight.</strong><br/><br/><strong>Short version:</strong><br/><br/>Dough is fickle, this app doesn't produce values that fit easily into measuring cups. Weight measurements are much more accurate because they're not restricted to a handful of set amounts; they're variable.<br/><br/><strong>Long version:</strong><br/><br/>For starters, do you pack or fluff your flour, spoon or scoop, flatten off with a knife? Is it precisely the same amount every time? If not, your dough will always turn out differently. How do you measure 0.933 tablespoons of oil? How about 0.371 tablespoons of yeast? Please don't say you'd eyeball it.<br/><br/>Measurements by weight are superior in terms of accuracy, while volumetric measurements tend to vary too greatly. For this reason, I <em>highly</em> suggest you stick with weight measurements instead of volumetric. Some volumetric conversions are provided, but please, if you can, invest in a kitchen scale. The consistency of your dough could improve drastically.",
    },
    {
      key: "step1_3",
      text: "If by hand, mix in a fold-and-roll motion. If using a mixer, use the dough hook attachment. <br/><br/> The extra mixing allows for gluten strands to be formed, allowing the dough to stretch later on.",
    },
    {
      key: "step2_1",
      text: "Most US home ovens max out at 550° fahrenheit (about 288° celsius). If you have a convection option, that will help.",
    },
    {
      key: "step2_2",
      text: "If you don't enjoy cornmeal on the bottom of your pizzas, consider using semolina or roasted flour instead. To make roasted flour, put a cup of flour in a pan at medium-to-high heat, and watch it carefully (it burns fast!). Stir occasionally, especially once it gets hot enough. When golden brown, remove from heat and use that to prevent your dough from sticking on a stone or paddle.",
    },
    {
      key: "step2_3",
      text: "A couple of popular ways to stretch dough before topping: <br /><br/>1. Gravity method. After pushing your dough into a puck shape on your surface, grasp one side using both hands. Make sure all non-thumb fingers are grasping the dough like a clamp. Hold dough above your surface and let gravity stretch it out. Rotate the dough through your hands as to ensure all sides can stretch out, and that no one side stretches enough to tear the dough.<br /><br/>2. Knuckles method. This usually works best once the dough has been stretched out just a little, possibly by the gravity method. Place the dough on top of the back of your knuckles, fingers bent toward your palm. Ensure your knuckles are holding up the dough on one side. Now slowly and gently pull your knuckles apart, stretching that edge of the dough. Scoot the dough in a small rotation, repeating the stretching all around the dough edges. Essentially you're using your knuckles to stretch out the edges of the dough in a rotational fashion, which in turn will expand the dough. Consider using floured hands (or knuckles) to prevent sticking. <br><br/>For other methods such as the slap, spin throw, or others, there are great tutorials on YouTube.",
    },
    {
      key: "step2_4",
      text: "- For cheese it's best to use high fat (whole milk), low moisture cheese. Higher fat cheese melts and cooks better, and low moisture cheese will prevent your dough from immediately getting soggy.<br><br>- For an easy pizza sauce, use a high quality can of crushed tomatoes (add tomato paste if more flavor and thickness is needed), and salt to taste. If desired, add a little garlic salt. Oregano, Italian Seasoning, or Basil also tastes great. A pinch of sugar can help too.",
    },
  ],
};
